import React, { useState, useEffect, useMemo } from 'react';

const ChannelDropdown = ({ channelNicknames, selectedChannelNicknames, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('all-channels');

  const sortedChannelNicknames = useMemo(() => {
    return [...channelNicknames].sort((a, b) => a.localeCompare(b));
  }, [channelNicknames]);

  useEffect(() => {
    if (selectedChannelNicknames.size === channelNicknames.length) {
      setDisplayValue('all-channels');
    } else if (selectedChannelNicknames.size === 1) {
      setDisplayValue(Array.from(selectedChannelNicknames)[0]);
    } else {
      setDisplayValue('all-channels'); // Reset to 'All' if multiple channels are selected
    }
  }, [selectedChannelNicknames, channelNicknames]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (nickname) => {
    let newSelection;
    if (nickname === 'all-channels') {
      newSelection = new Set(channelNicknames);
    } else {
      newSelection = new Set([nickname]);
    }
    onChange(newSelection);
    setIsOpen(false);
  };

  return (
    <div style={{ marginBottom: '20px', position: 'relative' }}>
      <label htmlFor="channel-select"> </label>
      <div
        id="channel-select"
        onClick={handleToggle}
        style={{
          width: '200px',
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          cursor: 'pointer',
          backgroundColor: '#fff',
        }}
      >
        {displayValue}
      </div>
      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '200px',
          maxHeight: '200px',
          overflowY: 'auto',
          border: '1px solid #ccc',
          borderTop: 'none',
          borderRadius: '0 0 4px 4px',
          backgroundColor: '#fff',
          zIndex: 1000,
        }}>
          <div
            onClick={() => handleOptionClick('all-channels')}
            style={{
              padding: '5px',
              cursor: 'pointer',
              backgroundColor: selectedChannelNicknames.size === channelNicknames.length ? '#e0e0e0' : '#fff',
            }}
          >
            all-channels
          </div>
          {sortedChannelNicknames.map((nickname) => (
            <div
              key={nickname}
              onClick={() => handleOptionClick(nickname)}
              style={{
                padding: '5px',
                cursor: 'pointer',
                backgroundColor: selectedChannelNicknames.has(nickname) && selectedChannelNicknames.size === 1 ? '#e0e0e0' : '#fff',
              }}
            >
              {nickname}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChannelDropdown;